// AgeVerifier.jsx
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withTranslation } from 'react-i18next';

import { ButtonId } from '../resource/mixpanel.js';
import { TranslationNamespace } from '../resource/translationNamespace.js';
import AdultOnlyIconSource from '../../img/img-under-18.svg';

export class AgeVerifier extends React.PureComponent {
  state = { isServer: true };
  nextTick = null;

  componentDidMount() {
    this.nextTick = setTimeout(() => this.setState({ isServer: false }), 0);
  }

  componentWillUnmount() {
    clearTimeout(this.nextTick);
  }
  renderDescription() {
    const { t } = this.props;
    const explode = t('age_verify_description', {
      ns: TranslationNamespace.GENERAL,
    })
      .replace(/(terms_of_service)/g, '__$1__')
      .split('__');
    return (
      <ContentDescription>
        {explode.map((s, index) =>
          'terms_of_service' === s ? (
            <a href="https://swag.live/terms_of_service" key={index}>
              {t('terms_of_service', {
                ns: TranslationNamespace.GENERAL,
              })}
            </a>
          ) : (
            s
          )
        )}
      </ContentDescription>
    );
  }
  confirm = () => {
    this.props.setConfirmTimestamp();
    this.props.closeModal();
  };

  render() {
    const { isServer } = this.state;
    const { t } = this.props;
    return (
      !isServer && (
        <Wrapper onScroll={e => e.stopPropagation()}>
          <StyledAgeVerifier>
            <AdultOnlyIcon src={AdultOnlyIconSource} alt="adult only icon" />
            <TitleBlock>
              <Title>
                {t('overlay_header_happyhour', {
                  ns: TranslationNamespace.USER_KYC,
                })}
              </Title>
              <Description>
                {t('overlay_instruction_happyhour', {
                  ns: TranslationNamespace.USER_KYC,
                })}
              </Description>
            </TitleBlock>
            <Warning>
              {t('warning', {
                ns: TranslationNamespace.GENERAL,
              })}
            </Warning>
            <ContentTitle>
              {t('age_verify_title', {
                ns: TranslationNamespace.GENERAL,
              })}
            </ContentTitle>
            {this.renderDescription()}
            <Options>
              <Enter
                onClick={this.confirm}
                data-element_id={ButtonId.All.ButtonEnterSWAG}
              >
                {t('agree_terms_of_service_button_highlight', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </Enter>
              <Exit
                href="http://www.google.com/search?q=grow up"
                title="exit"
                data-element_id={ButtonId.All.ButtonExitSWAG}
              >
                {t('exit', {
                  ns: TranslationNamespace.GENERAL,
                })}
              </Exit>
            </Options>
          </StyledAgeVerifier>
        </Wrapper>
      )
    );
  }
}

AgeVerifier.propTypes = {
  closeModal: PropTypes.func,
  setConfirmTimestamp: PropTypes.func,
  t: PropTypes.func.isRequired,
};

AgeVerifier.defaultProps = {
  setConfirmTimestamp: () => null,
  closeModal: () => null,
};

const Wrapper = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  background: none;
  padding: 8px;
  color: black;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const StyledAgeVerifier = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: calc(50% + 25px);
  left: 50%;
  padding: 32px 16px 0px;
  max-width: 300px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 4px;
`;

const TitleBlock = styled.div`
  padding: 0 20px;
  text-align: center;
`;

const Title = styled.h2`
  margin: 0 0 8px;
  color: #646464;
  font-size: 18px;
`;

const Description = styled.p`
  margin: 0;
  padding: 0 0 12px;
  color: #646464;
  font-size: 12px;
`;

const AdultOnlyIcon = styled.img`
  margin-top: -82px;
  width: auto;
  height: 100px;
`;

const Warning = styled.h4`
  margin: 0px 0px 16px 0px;
  width: 100%;
  color: #fff;
  background-color: #cf0404;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
`;

const ContentTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  color: #646464;
`;

const ContentDescription = styled.p`
  margin: 12px 0px 0px;
  width: 100%;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
  line-height: 20px;
  white-space: pre-line;
  overflow: auto;
  color: #646464;
  max-height: 140px;
  overflow-y: scroll;
  & > a {
    text-decoration: underline;
  }
`;

const Options = styled.div`
  margin-top: 16px;
  width: 100%;
`;

const Exit = styled.a`
  display: block;
  height: 56px;
  line-height: 56px;
  text-align: center;
  color: #c8c8c8;
  font-size: 12px;
`;

const Enter = styled.button`
  outline: none;
  border: 0px;
  border-radius: 50px;
  background-color: #00d2be;
  padding: 12px;
  width: 100%;
  color: white;
  text-align: center;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
`;

export default withTranslation()(AgeVerifier);
