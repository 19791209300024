// AgeVerifier.js
import { connect } from 'react-redux';
import mergeMeData from '../action/mergeMeData.js';
import AgeVerifier from '../component/AgeVerifier.jsx';
import removeModals from '../action/removeModals.js';

function mapDispatchToProps(dispatch) {
  return {
    setConfirmTimestamp: () => {
      const now = Date.now();
      dispatch(
        mergeMeData({ field: 'lastAgeVerifierConfirmTimestamp', value: now })
      );
    },
    closeModal: () =>
      dispatch(
        removeModals({ ids: ['AgeVerifierModal'], transitionStatus: 'closing' })
      ),
  };
}

export default connect(null, mapDispatchToProps)(AgeVerifier);
