// AgeVerifierModal.js
import { connect } from 'react-redux';
import getMeData from '../selector/getMeData.js';
import getOperationData from '../selector/getOperationData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';
import getIsRemoteConfigMerged from '../selector/getIsRemoteConfigMerged.js';
import getModalData from '../selector/getModalData.js';
import { getIsInIframe } from '../resource/getJsEnvironment.js';
import { ModalStatus } from '../resource/modalConstants.js';
import AgeVerifierModal from '../component/AgeVerifierModal.jsx';
import removeModals from '../action/removeModals.js';
import addModal from '../action/addModal.js';
import mergeMeData from '../action/mergeMeData.js';

import {
  NEED_LANDING_COUNTRIES,
  NEED_OVER_18_COUNTRIES,
  AGE_VERIFIER_COOLDOWN_MSEC,
} from '../RemoteConfigKeys.js';
import {
  CONFIG_PRIORITY_CONFIGURE,
  CONFIG_PRIORITY_PRESENCE_CLIENT,
} from '../resource/configPriority.js';

const isInIframe = getIsInIframe();

function mapStateToProps(state) {
  const priorityGroups = getOperationData(
    state,
    ['config'],
    'configurationGroups'
  );
  const usingRemoteConfigPriority = priorityGroups?.[
    CONFIG_PRIORITY_PRESENCE_CLIENT
  ]
    ? CONFIG_PRIORITY_PRESENCE_CLIENT
    : CONFIG_PRIORITY_CONFIGURE;
  const isRemoteConfigMerged = getIsRemoteConfigMerged(
    state,
    usingRemoteConfigPriority
  );

  const isModalDisplayed = [ModalStatus.OPENING, ModalStatus.DISPLAY].includes(
    getModalData(state, 'AgeVerifierModal', 'status')
  );

  const isAuthed = !!getMeData(state, 'refreshToken');
  const userCountry = getMeData(state, 'country');
  const needLandingCountries =
    getRemoteConfigData(state, NEED_LANDING_COUNTRIES) || [];
  const needOver18Countries =
    getRemoteConfigData(state, NEED_OVER_18_COUNTRIES) || [];
  const isInNeedLandingCountries = needLandingCountries.includes(userCountry);
  const shouldShowLanding = !isInIframe && isInNeedLandingCountries;
  const isInNeedOver18Countries = needOver18Countries.includes(userCountry);
  const shouldShowAgeVerifier =
    !isInIframe && isInNeedOver18Countries && !shouldShowLanding;

  const AgeVerifierCooldownMsec =
    getRemoteConfigData(state, AGE_VERIFIER_COOLDOWN_MSEC) || [];
  const now = Date.now();
  const lastAgeVerifierConfirmTimestamp =
    getMeData(state, 'lastAgeVerifierConfirmTimestamp') || 0;
  const isAgeVerifiedInValidPeriod =
    now - lastAgeVerifierConfirmTimestamp < AgeVerifierCooldownMsec;

  return {
    isAuthed,
    isModalDisplayed,
    isAgeVerifiedInValidPeriod,
    isRemoteConfigMerged,
    shouldShowLanding,
    shouldShowAgeVerifier,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setConfirmTimestamp: () => {
      const now = Date.now();
      dispatch(
        mergeMeData({ field: 'lastAgeVerifierConfirmTimestamp', value: now })
      );
    },
    openModal: () => dispatch(addModal({ id: 'AgeVerifierModal' })),
    closeModal: () =>
      dispatch(
        removeModals({ ids: ['AgeVerifierModal'], transitionStatus: 'closing' })
      ),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AgeVerifierModal);
