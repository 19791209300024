// AgeVerifierModal.jsx
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import ModalView from '../container/ModalView.js';
import AgeVerifier from '../container/AgeVerifier.js';

import { ModalId } from '../resource/mixpanel.js';
import { NULL_FUNCTION } from '../resource/defaults.js';

export const AgeVerifierModal = ({
  isAuthed = false,
  isModalDisplayed = false,
  isAgeVerifiedInValidPeriod = false,
  isRemoteConfigMerged = false,
  shouldShowLanding = false,
  shouldShowAgeVerifier = false,
  setConfirmTimestamp = NULL_FUNCTION,
  openModal = NULL_FUNCTION,
  closeModal = NULL_FUNCTION,
}) => {
  useEffect(() => {
    const shouldShow =
      isRemoteConfigMerged &&
      !shouldShowLanding &&
      shouldShowAgeVerifier &&
      !isAgeVerifiedInValidPeriod;
    if (shouldShow && !isModalDisplayed) {
      if (isAuthed) {
        setConfirmTimestamp();
      } else {
        openModal();
      }
    } else if (!shouldShow && isModalDisplayed) {
      closeModal();
    }
  }, [
    isAuthed,
    isModalDisplayed,
    isAgeVerifiedInValidPeriod,
    isRemoteConfigMerged,
    shouldShowLanding,
    shouldShowAgeVerifier,
    setConfirmTimestamp,
    closeModal,
    openModal,
  ]);

  return (
    <ModalView
      id="AgeVerifierModal"
      modalId={ModalId.Landing.ModalOver18}
      render={() => <AgeVerifier />}
    />
  );
};

AgeVerifierModal.propTypes = {
  isAuthed: PropTypes.bool,
  isModalDisplayed: PropTypes.bool,
  shouldShowLanding: PropTypes.bool,
  shouldShowAgeVerifier: PropTypes.bool,
  isRemoteConfigMerged: PropTypes.bool,
  isAgeVerifiedInValidPeriod: PropTypes.bool,
  setConfirmTimestamp: PropTypes.func,
  openModal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default AgeVerifierModal;
